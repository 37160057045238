html, body, #root, #root >div, .App {
    height:100%;
    overflow: hidden;
}

* {
    -webkit-overflow-scrolling: touch;
    backface-visibility: hidden;
}

/* Fix for RTL perfect scrollbars */
body[dir="rtl"] .ps__rail-y {
    right: auto !important;
    left: 0 !important;
}